body {
  margin: 0 auto;
  /*padding: 0 10%;*/
  /*max-width: 1200px;*/
}

h1{
  
  font-size: 48px;
  font-weight: 400;
  line-height: 55px;
  font-family: 'Oswald';
}

.btn_med {
  width: 124px;
  height: 38px;
  border-radius: 5px;
  background-color: #111111;
  color: #F0F0F0;
  font-weight: 700;
}


.leaflet-pane {
  z-index: 400;
  transform: translate3d(8px, 256px, 0px);
}
.App {
font-family: sans-serif;
text-align: center;
}
.leaflet-div-icon {
background: transparent;
border: none;
}
.button {
position: absolute;
top: 100px;
left: 100px;
z-index: 10000;
}
.leaflet-container {
height:500px; 
background: #F0F0F0;
width: 100%;
}
.leaflet-bar a:last-child{
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
border-bottom: none;
}
#map {width: 100%; height:100%; position: absolute; }


/*
source
http://stackoverflow.com/a/23935891/3853728
*/

.imgGradient{
  position:relative;
  display:inline-block;
}


/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.imgGradient:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#111111',GradientType=0 ); /* IE6-9 */
}
.imgGradient img{
  display:block;
}

.field--name-field-content{background:#f8f8f8;padding:1em;padding-top:1em !important;margin-top:1em;}
.clearfix:after{display:table;clear:both;content:"";}
.sponsor-title { display: none;}  /*hide unwanted link (<a> tag) from exhibition overview content*/