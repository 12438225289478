@font-face {
  font-family: 'CommonGround_Normal';
  src: url('CommonGround_Normal.eot');
  src: url('CommonGround_Normal.eot?#iefix') format('embedded-opentype'),
       url('CommonGround_Normal.woff') format('woff'),
       url('CommonGround_Normal.ttf') format('truetype'),
       url('CommonGround_Normal.svg') format('svg');
  font-weight: normal;
  font-style: normal;
 }

 @font-face {
  font-family: 'CommonGround_Bold';
  src: url('CommonGround_Bold.eot');
  src: url('CommonGround_Bold.eot?#iefix') format('embedded-opentype'),
       url('CommonGround_Bold.woff') format('woff'),
       url('CommonGround_Bold.ttf') format('truetype'),
       url('CommonGround_Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
 }

  @font-face {
  font-family: 'CommonGround_Normal_Italic';
  src: url('CommonGround_Normal_Italic.eot');
  src: url('CommonGround_Normal_Italic.eot?#iefix') format('embedded-opentype'),
       url('CommonGround_Normal_Italic.woff') format('woff'),
       url('CommonGround_Normal_Italic.ttf') format('truetype'),
       url('CommonGround_Normal_Italic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
 }

 @font-face {
  font-family: 'CommonGround_Bold_Italic';
  src: url('CommonGround_Bold_Italic.eot');
  src: url('CommonGround_Bold_Italic.eot?#iefix') format('embedded-opentype'),
       url('CommonGround_Bold_Italic.woff') format('woff'),
       url('CommonGround_Bold_Italic.ttf') format('truetype'),
       url('CommonGround_Bold_Italic.svg') format('svg');
  font-weight: bold;
  font-style: normal;
 }